:root {
  --heading-font: 90px;
  --heading-font-media: 90px;
  --sub-heading: 20px;
  --sub-heading-media: 20px;
  --box-shadow-value: 0px 0px 20px -10px black;
  --icon-size: 70px;
}

.home-header {
  display: flex;
  justify-content: space-between;
  background: url("../../resources/svg/topography.svg");
}

.home-logo {
  width: 75px;
  padding: 20px;
}

.home-nav {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.home-nav-mobile {
  display: none;
}

.home-nav li {
  padding: 20px;
  padding-right: 40px;
  font-size: 18px;
  transition: 0.3s;
}

.home-nav li:hover {
  font-weight: 500;
  cursor: pointer;
}

.home-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.home-intro-text {
  /*TODO: FONT TO BE CHANGED */
  font-family: "darkline";
  text-align: center;
  font-size: 80px;
  line-height: 60px;
  width: 50%;
}

.home-intro-text span {
  font-family: "Montserrat";
  font-size: 25px;
  width: 100%;
}

.home-spline {
  aspect-ratio: 16/9;
  width: 40%;
}

.home-spline-mobile {
  display: none;
}

.home-work {
  width: 100%;
}
.work-heading {
  text-align: center;
  font-family: "Bebas Neue";
  font-size: var(--heading-font);
  line-height: 30px;
}

.work-heading span {
  font-size: var(--sub-heading);
  font-weight: 300;
}

.work-grid {
  padding-top: 10px;
  width: 100vw;
}

.over {
  overflow: hidden;
  position: relative;
  height: clamp(11rem, 10.0909rem + 2.4242vw, 13rem);
}

.work-image {
  transition: 0.5s;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0)
  );
  transition: 0.5s;
  display: flex;
  color: white;
  font-family: "Bebas Neue Book";
  font-size: 30px;
  align-items: center;
  justify-content: space-between;
}

.outline-text {
  font-family: "Bebas Neue", sans-serif;
  font-size: clamp(3rem, 2.0909rem + 2.4242vw, 5rem);
  color: rgb(0, 0, 0, 0);
  -webkit-text-stroke: 1px white;
  transition: 0.5s;
}

.overlay div {
  padding: 20px;
}

.over:hover div {
  opacity: 1;
}

.over:hover .outline-text {
  color: rgb(255, 255, 255);
}

.over:hover img {
  transform: scale(1.1);
}

.book-intro {
  margin-top: 100px;
  padding-left: 50px;
}

.book-heading {
  font-family: "Bebas Neue";
  font-size: var(--heading-font);
}

.book-sub {
  font-family: "Bebas Neue Book";
  font-size: var(--sub-heading);
}

.book-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 85vh;
  width: 100%;
}

.book-spline {
  aspect-ratio: 16/9;
  width: 830px;
}

.book-form {
  box-shadow: var(--box-shadow-value);
  margin: 10px;
  padding: 30px;
  border-radius: 30px;
  width: 80%;
  max-width: 450px;
  min-width: 320px;
}

.form-input {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.form-button-submit {
  height: 40px;
  outline: none;
  background-color: #7567e5;
  border: none;
  border-radius: 3px;
  font-family: "Bebas Neue";
  font-size: 20px;
  color: white;
}

.form-input div {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.form-input label {
  padding-bottom: 7px;
  font-size: 15px;
  font-family: "Bebas Neue";
  transition: all 0.3s ease-in-out;
  color: black;
}

.form-input-name,
.form-input-mail,
.form-input-content {
  height: 30px;
  font-size: 20px;
  padding: 5px;
  font-family: "Montserrat";
  transition: border 0.3s ease-in-out;
  border: 2px solid black;
  border-radius: 3px;
  outline: none;
}

.form-input-content {
  height: 150px;
  resize: none;
}

.form-input-name:active {
  border-color: #ffa6cb;
}

.form-input-name:hover {
  border-color: #ffa6cb;
}

.form-input-name:focus {
  border-color: #ffa6cb;
}

.name:focus-within .name-label {
  color: #ffa6cb;
}

.form-input-mail:active {
  border-color: #ffa6cb;
}

.form-input-mail:hover {
  border-color: #ffa6cb;
}

.form-input-mail:focus {
  border-color: #ffa6cb;
}

.mail:focus-within .mail-label {
  color: #ffa6cb;
}

.form-input-content:active {
  border-color: #ffa6cb;
}

.form-input-content:hover {
  border-color: #ffa6cb;
}

.form-input-content:focus {
  border-color: #ffa6cb;
}

.content:focus-within .content-label {
  color: #ffa6cb;
}
/* Footer */

.footer-distributed {
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 200px -80px black;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px "Montserrat";
  padding: 25px 45px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

.footer-distributed .footer-left {
  width: 40%;
}

.footer-distributed h4 {
  color: #000000;
  font: normal 80px "Darkline";
  line-height: 50px;
  padding-top: 40px;
  margin: 0;
}

.footer-distributed h3 span {
  color: lightseagreen;
}

.footer-distributed .footer-links {
  color: #000000;
  margin: 20px 0 12px;
  padding: 0;
  display: grid;
}

.footer-distributed .footer-links div {
  display: inline-block;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.footer-distributed {
  color: #222;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #33383b;
  color: #000000;
  font-size: 30px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #000000;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: lightseagreen;
  text-decoration: none;
}

.footer-distributed .footer-links a:before {
  font-weight: 300;
  font-size: 20px;
  left: 0;
  color: #000000;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
  width: 20%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #000000;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  font-size: 22px;
  color: #000000;
  text-align: center;
  margin-right: 3px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {
  .footer-distributed {
    font: bold 14px "Montserrat";
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}

/* Footer End */
#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition-duration: 0.5s;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
}

#bar2 {
  transition-duration: 0.8s;
}

#bar1,
#bar3 {
  width: 70%;
}

#checkbox:checked + .toggle .bars {
  position: absolute;
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar2 {
  transform: scaleX(0);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar1 {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar3 {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle {
  transition-duration: 0.5s;
  transform: rotate(180deg);
}
/* Harburger Ends */

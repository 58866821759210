.welcome-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.664);
    backdrop-filter: blur(5px);
    animation: hide 1s ease-in-out forwards;
    animation-delay: 6s;
    z-index: 999;
}

.welcome-svg{
    padding-bottom: 30px;
    fill: none;
    width: 100%;
}

.welcome-svg path{
    stroke: white;
    stroke-dasharray: 6880;
    stroke-dashoffset: 6880;
    animation: dash 5s ease-in-out forwards;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.welcome-subline{
    font-family: 'Bebas Neue Book';
    font-size: 6vw;
    letter-spacing: 3vw;
    text-indent: 3vw;
    color: white;
    opacity: 0;
    animation: reveal 1s ease-in-out forwards;
    animation-delay: 5s;
}

@keyframes reveal {
    to {
       opacity: 1;
    }
}

@keyframes hide {
    to {
        opacity: 0;
        display: none;
        visibility: hidden;
    }
}
.galleria-main {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}

.card {
  width: 100%;
  background-size: cover;
  transition: all 400ms ease-in-out !important;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
}

.card:hover {
  flex-grow: 6 !important;
}

.image-1 {
  background-image: url("https://dg2h7a60hb825.cloudfront.net/CATEGORIES/WILDLIFE.jpg");
}

.image-2 {
  background-image: url("https://dg2h7a60hb825.cloudfront.net/CATEGORIES/COMMERCIAL.jpg");
}
.image-3 {
  background-image: url("https://dg2h7a60hb825.cloudfront.net/CATEGORIES/INTERIOR.jpg");
}
.image-4 {
  background-image: url("https://dg2h7a60hb825.cloudfront.net/CATEGORIES/PRODUCT.jpg");
}
.image-5 {
  background-image: url("https://dg2h7a60hb825.cloudfront.net/CATEGORIES/JEWELLERY.jpg");
}
.image-6 {
  background-image: url("https://dg2h7a60hb825.cloudfront.net/CATEGORIES/NEW_BORN.jpg");
}
.image-7 {
  background-image: url("https://dg2h7a60hb825.cloudfront.net/CATEGORIES/MATERNITY.jpg");
}
.image-8 {
  background-image: url("https://dg2h7a60hb825.cloudfront.net/CATEGORIES/FASHION.jpg");
}
.image-9 {
  background-image: url("https://dg2h7a60hb825.cloudfront.net/CATEGORIES/MÉLANGE.jpg");
}

.heading {
  color: white;
  font-family: "Bebas Neue Book";
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  mix-blend-mode: difference;
}

.card:hover .heading {
  letter-spacing: 40px;
  text-indent: 40px;
  font-size: 80px;
}

.hide {
  display: none;
}

.album-heading {
  font-family: "Bebas Neue";
  font-size: 60px;
  letter-spacing: 40px;
  text-indent: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.album-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../resources/svg/topography.svg");
  background-attachment: fixed;
  padding-bottom: 20px;
}

.album-close {
  position: relative;
  right: 0;
  color: rgb(0, 0, 0, 0);
  -webkit-text-stroke: 1px black;
  transition: 0.5s;
}

.album-close:hover {
  color: rgb(0, 0, 0);
}

.album-slideshow {
  font-family: "Bebas Neue";
  font-size: 35px;
  padding: 20px;
  background-color: rgb(255, 183, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-radius: 10px;
}

.album-outline-text {
  color: rgb(0, 0, 0, 0);
  -webkit-text-stroke: 1px black;
  transition: 0.5s;
}

.album-slideshow:hover .album-outline-text {
  color: rgb(0, 0, 0);
}

/* Loader CSS */

.spinner {
  position: absolute;
  width: 9px;
  height: 9px;
}

.spinner div {
  position: absolute;
  width: 50%;
  height: 150%;
  background: #000000;
  transform: rotate(calc(var(--rotation) * 1deg))
    translate(0, calc(var(--translation) * 1%));
  animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
}

.spinner div:nth-child(1) {
  --delay: 0.1;
  --rotation: 36;
  --translation: 150;
}

.spinner div:nth-child(2) {
  --delay: 0.2;
  --rotation: 72;
  --translation: 150;
}

.spinner div:nth-child(3) {
  --delay: 0.3;
  --rotation: 108;
  --translation: 150;
}

.spinner div:nth-child(4) {
  --delay: 0.4;
  --rotation: 144;
  --translation: 150;
}

.spinner div:nth-child(5) {
  --delay: 0.5;
  --rotation: 180;
  --translation: 150;
}

.spinner div:nth-child(6) {
  --delay: 0.6;
  --rotation: 216;
  --translation: 150;
}

.spinner div:nth-child(7) {
  --delay: 0.7;
  --rotation: 252;
  --translation: 150;
}

.spinner div:nth-child(8) {
  --delay: 0.8;
  --rotation: 288;
  --translation: 150;
}

.spinner div:nth-child(9) {
  --delay: 0.9;
  --rotation: 324;
  --translation: 150;
}

.spinner div:nth-child(10) {
  --delay: 1;
  --rotation: 360;
  --translation: 150;
}

@keyframes spinner-fzua35 {
  0%,
  10%,
  20%,
  30%,
  50%,
  60%,
  70%,
  80%,
  90%,
  100% {
    transform: rotate(calc(var(--rotation) * 1deg))
      translate(0, calc(var(--translation) * 1%));
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg))
      translate(0, calc(var(--translation) * 1.5%));
  }
}

/* Loader CSS End */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@font-face {
  font-family: "darkline";
  src: url("./resources/font/Darkline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue Book";
  src: url("./resources/font/BebasNeue-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./resources/font/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  user-select: none;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  --c0: #000000;
  --c1: #ffffff;
}

::-webkit-scrollbar {
  width: 3px;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-track {
  background: rgb(64, 64, 64);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: black;
}

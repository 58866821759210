/* From Uiverse.io by Christiaan-code */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box-1,
.box-2,
.box-3 {
  width: 10px;
  height: 115px;
  border: 1px solid #000000;
  background-color: transparent;
  box-sizing: border-box;
}

.box-1 {
  animation: box1animation 3s forwards ease-in-out infinite;
}

@keyframes box1animation {
  0% {
    width: 10px;
  }

  16.7% {
    width: 60px;
  }

  33.3% {
    width: 60px;
  }

  50% {
    width: 10px;
  }

  100% {
    width: 10px;
  }
}

.box-2 {
  animation: box2animation 3s forwards ease-in-out infinite;
}

@keyframes box2animation {
  0% {
    width: 10px;
  }

  33.3% {
    width: 10px;
  }

  50% {
    width: 60px;
  }

  66.6% {
    width: 60px;
  }

  83.3% {
    width: 10px;
  }

  100% {
    width: 10px;
  }
}

.box-3 {
  animation: box3animation 3s forwards ease-in-out infinite;
}

@keyframes box3animation {
  0% {
    width: 60px;
  }

  16.7% {
    width: 10px;
  }

  66.6% {
    width: 10px;
  }

  83.3% {
    width: 60px;
  }

  100% {
    width: 60px;
  }
}

@media only screen and (max-width: 600px) {
  :root {
    --heading-font: 3.6rem;
    --sub-heading: 1.1rem;
    --icon-size: 65px;
  }

  .home-nav {
    display: none;
    position: fixed;
    left: 100vw;
    width: 100%;
    height: 100%;
    font-family: "Montserrat";
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-direction: column;
    margin-block-start: unset;
    margin-block-end: unset;
    padding-inline-start: unset;
    z-index: 1;
  }

  .home-nav li {
    padding: 50px;
    font-size: 30px;
    font-family: "Montserrat";
    transition: 0.3s;
  }

  .home-nav-mobile {
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .home-intro {
    flex-direction: column-reverse;
  }

  .home-intro-text {
    width: 100%;
    font-size: 60px;
  }

  .home-intro-text span {
    font-size: 15px;
  }

  .home-spline {
    width: 100%;
  }

  .more {
    font-size: 1.9rem;
    flex-direction: column;
    align-items: unset;
  }

  .more span {
    display: none;
  }

  .book-intro {
    text-align: center;
    padding-left: 0px;
    margin: 20px;
    flex-direction: column;
    margin-top: 100px;
  }

  .book-container {
    flex-direction: column;
    min-height: 85vh;
  }

  .book-spline {
    aspect-ratio: unset;
    width: 380px;
    height: 600px;
  }
}
